@use 'variable' as *;
@use 'mixin';

.section-columns {
    padding-top: 2em;

    > div {
        display: inline-flex;
        position: relative;
    }

    .column-content {
        background-color: $column;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2em 3em 4em;

        > div:last-of-type {
            display: flex;
            justify-content: center;
            margin-top: 3em;
        }
    }

    h2 {
        @include mixin.font-set(1.8em, $column-text, 700);

        text-align: center;
    }

    h3 {
        @include mixin.font-set(1.6em, $column-text, 400);

        margin-bottom: 0.8em;
        padding-top: 0;
    }

    h4 {
        @include mixin.font-set(1.1em, $column-text, 400);

        padding-bottom: 0;
    }

    p {
        @include mixin.font-set(1.1em, $column-text, 400);

        text-align: left;
    }

    .btn-primary {
        @include mixin.font-set(1.8em, $white, 700);

        background-color: $column-text;
        box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.075);
        padding: 0.5em 1em;
        transition: all 0.2s ease-in-out;

        &:hover,
        &:focus {
            background-color: lighten($column-text, 10);
            color: $white;
        }
    }
}
