@use 'variable' as *;

.djangocms-newsblog-content {
    margin-top: 2em;
    padding: 0 14vw;
    padding-top: 2em;

    .article-detail,
    .articles-list-item {
        a {
            color: $column-text;
            text-decoration: underline;

            &:hover {
                color: $input-text;
            }
        }
    }

    h2.article-title {
        font-size: 1.8em;
        padding-top: 1em;

        a {
            color: $column-text;
            text-decoration: underline;

            &:hover {
                color: $input-text;
            }
        }
    }
}
