@use 'variable' as *;

.csirt-stats table {
    tr {
        display: table-row;

        th {
            text-align: left;
        }

        td {
            display: table-cell;
            text-align: right;
            width: auto;
        }
    }
}

div.unavailable {
    position: relative;

    div.message {
        background-color: $white;
        border: solid $border2 1px;
        box-shadow: 3px 4px 2px 1px $box-shadow;
        left: 2em;
        padding: 1em 2em;
        position: absolute;
        top: 2em;

        span {
            color: $error;
        }
    }

    .csirt-stats table {
        tr {
            height: 1.2em;
        }

        th,
        td {
            width: 3.6em;
        }
    }
}
