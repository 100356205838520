@use 'variable' as *;
@use 'mixin';

/*
*
* aldryn-newsblog-pagination, pagenav
*
*/
.djangocms-newsblog-article-list,
.pagenav {
    ul,
    ul.pagination {
        li {
            display: inline-block;
            margin-left: 0.4em;

            a {
                @include mixin.font-set(1em, $title, 300);

                border: 1px solid $title;
                display: inline-block;
                line-height: 1.55em;
                min-width: 1.55em;
                padding: 0.2em 0.4em;

                &:hover {
                    border: 1px solid $error;
                    color: $error;
                }
            }

            span {
                display: inline-block;
                line-height: 1.55em;
                min-width: 1.55em;
                padding: 0.2em 0.4em;
            }
        }

        li.active span {
            @include mixin.font-set(1em, $title, 300);

            display: inline-block;
            line-height: 1.55em;
            min-width: 1.55em;
            text-align: center;
        }
    }
}
