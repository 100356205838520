@use 'variable' as *;
@use 'fonts' as *;
@use 'mixin';

html {
    font-family: 'Roboto', sans-serif;
    font-size: 95%;
}

h1 {
    @include mixin.font-set(2.31em, $white, bold);
}

h2 {
    @include mixin.font-set(1.4em, $hover-color, 300);

    text-align: left;
}

h3 {
    @include mixin.font-set(1.25em, $title, bold);
}

h1,
h2,
h3 {
    line-height: 1.33em;
    margin-bottom: 0.66em;
}

h4,
h5,
h6 {
    @include mixin.font-set(1.1em, $title, bold);

    line-height: 1.5;
    padding: 0 0 1em;
}

h5 {
    padding: 0;
}

a {

    @include mixin.linx($hover-color, $title, $title);

    color: $title;
    text-align: center;
    text-decoration: none;

    &:hover {
        color: $hover-color;
    }
}

p {
    @include mixin.font-set(1em, $title, 300);

    line-height: 1.55;
    margin-bottom: 1em;
    text-align: justify;
}

strong {
    font-weight: bold;
}

pre {
    @include mixin.font-set(0.8em, $input-text, 300);

    padding: 0 0 1.6em;
}

ol {
    @include mixin.font-set(1em, $title, 300);

    line-height: 1.55;
    list-style-type: decimal;
    margin: 0 0 1em 1.5em;
}

/*
*
* Homepage
*
*/
.row-box,
.subject-top-border,
.aldryn-newsblog-pagination,
.container {
    padding: 0 $width-large;

    @media (max-width: 73em) {
        padding: 0 $width-middle;
    }

    @media (max-width: 55em) {
        padding: 0 $width-small;
    }

    a {
        color: $title;
    }
}

.row-box {
    margin-top: 4em;
    text-align: center;
}

body.show-body {
    background: $nav-line;
}

#pageContent.hide-content {
    display: none;
}

.clear {
    clear: both;
    margin-bottom: 2em;

    a {
        @include mixin.font-set(1em, $title, 300);
        @include mixin.linx($hover-color, none, none);

        display: block;
        text-align: center;
        text-decoration: underline;
    }
}

/*
*
* text-page
*
*/
.container {
    padding-top: 2em;

    @media (max-width: 55em) {
        padding-top: 0.5em;
    }

    h1 {
        @include mixin.font-set(1.8em, $hover-color, 300);
    }

    h2 {
        @include mixin.font-set(1.33em, $hover-color, 300);

        padding-top: 1em;
    }

    h3 {
        @include mixin.font-set(1.2em, $hover-color, 300);

        padding-top: 1em;
    }

    a {
        @include mixin.linx($hover-color, none, none);

        color: $title;
        text-decoration: underline;
    }

    h4 > a {
        font-weight: bold;
    }

    p strong a {
        font-weight: bold;
    }

    .row {
        margin: 0;
    }

    ul {
        margin: 0 0 1em 1.5em;

        li {
            color: $title;
            line-height: 1.55;
            list-style-image: url('../img/arrow-list.png');

            p {
                margin-bottom: 1em;
            }

            a,
            code {
                line-height: 1.5;
            }

            code {
                @media (max-width: 55em) {
                    font-size: 0.8em;
                }
            }
        }
    }

    .border-text,
    .news-version {
        border: 0.2em solid $border;
        padding: 0 2em 2em;

        .add-underline {
            border-bottom: 0.1em solid $border;
            display: block;
            padding-bottom: 1.2em;
            text-align: left;
        }

        pre {
            padding: 0 0 1em;
        }

        p:last-of-type .add-underline {
            margin-bottom: 3em;
        }
    }

    .news-version {
        border: 0;
        padding: 0;

        p:last-of-type .add-underline {
            border: 0;
        }
    }

    .col-lg-9 {
        padding-left: 3em;
    }

    table {
        @include mixin.font-set(1em, $title, 300);

        border: 1px solid $debug;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 1em 0;

        th {
            background-color: $bg-table;
            border-bottom: 1px solid $debug;
            border-left: 1px dotted $debug;
            font-weight: bold;
            line-height: 1.6;
            padding: 0.2em 6px;
            vertical-align: top;
        }

        td {
            border-bottom: 1px solid $debug;
            border-left: 1px dotted $debug;
            line-height: 1.6;
            padding: 0.2em 6px;
            vertical-align: top;
        }
    }

    .row.column-reverse {
        @media (max-width: 62em) {
            flex-direction: column-reverse;

            .col-lg-9 {
                padding-left: 1em;
            }
        }
    }

    img.filer_image {
        max-width: 100%;
    }
}

.container.active-submenu {
    margin-top: 2.6em;

    @media (max-width: 55em) {
        margin-top: 0;
    }
}

.col-lg-4,
.col-lg-3,
.col-lg-2 {
    padding-bottom: 3em;

    img {
        height: 9em;
    }

    a:hover {
        color: $hover-color;
    }
}

.subject-top-border {
    border-top: 0.2em solid $border;
    margin-top: 4.5em;
    padding-top: 1em;
    text-align: right;

    a:last-of-type {
        margin-right: 10%;
    }

    img {
        margin: 0 1em;
    }
}

.container > .faq {
    h4,
    h6 {
        padding: 0;
    }

    ol {
        list-style-type: decimal;
        margin: 0 0 1em 1.5em;

        li {
            line-height: 1.6;
        }
    }

    pre {
        background-color: $bg;
        border: 0.2em solid $border3;
        margin-bottom: 1em;
        overflow-x: auto;
        padding: 1em;

        code {
            @include mixin.font-set(1.2em, $title, 300);

            line-height: 1.55;

            tt {
                display: inline-block;
                width: 130px;
            }
        }
    }

    p {
        img {
            max-width: 40%;

            @media (max-width: 55em) {
                max-width: 100%;
            }
        }

        strong a {
            font-weight: bold;
        }
    }
}

body.show-body #page-navigation {
    display: block;
}

#clon-submenu {
    display: none;

    @media (max-width: 55em) {
        display: block;
        margin: 1em;
    }

    ul {
        border-bottom: 0.05em solid $nav-line;
        margin: 0;

        li {
            list-style-image: none;

            a {
                @include mixin.font-set(1em, $title, bold);

                display: inline-block;
                line-height: 1;
                padding: 1em;

                &.active_link {
                    box-shadow: -0.1em 0 0 0 $error;
                    color: $error;
                }
            }
        }
    }
}

body.show-body .container,
body.show-body footer,
body.show-body #clon-submenu {
    display: none;
}

.video {
    height: 70vh;
    width: 100%;

    iframe {
        height: 100%;
        width: 100%;
    }
}

a.btn {
    color: $less-white;
    text-decoration: none;

    &:hover {
        color: $white;
    }
}
