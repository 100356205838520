@use 'variable' as *;
@use 'mixin';

/*
*
* container reset bootstrap
*
*/
.container {
    max-width: 100%;

    @media (max-width: 55em) {
        margin-top: 2em;
    }

    .row {
        margin: 0;

        .col-md-17 {
            width: 100%;
        }
    }

    h2 {
        a {
            @include mixin.font-set(1em, $hover-color, 300);

            text-decoration: underline;

            &:hover {
                color: $title;
            }
        }
    }
}

/*
*
* aldryn newsblog pager
*
*/

.djangocms-newsblog-article-detail {
    ul.pagination {
        display: flex;
        justify-content: space-between;
        margin-left: 0;

        li {
            line-height: 1.5;
            list-style-image: none;

            > span {
                padding: 1em 0.66em;
            }

            span[aria-hidden='true'] {
                position: relative;
                top: -3px;
            }

            a {
                padding: 1em 0.66em;
                text-decoration: none;
            }

            &.previous-page a::before {
                content: "← ";
            }

            &.back-to-overview a {
                &::before {
                    content: "– ";
                }

                &::after {
                    content: " –";
                }
            }

            &.next-page a::after {
                content: " →";
            }
        }

        .previous,
        .next {
            > span {
                opacity: 0;
            }
        }
    }
}

/*
*
* aldryn-newsblog
*
*/
.aldryn-newsblog {
    .aldryn-newsblog-article {
        border-bottom: 1px solid $border;
        margin-bottom: 2em;
        padding-bottom: 2em;

        &:last-of-type {
            border: 0;
            padding-bottom: 0;
        }

        h2 {
            font-size: 1.8em;
        }
    }
}
