@use 'variable' as *;
@use 'mixin';

.menu-icon {
    display: none;
    position: absolute;
    right: 0.2em;
    top: 1em;
}

#closeMenu {
    display: none;
}

.page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1em $width-large;

    a {
        text-align: left;
    }

    .logo {
        height: 6em;
        margin: 0;
        max-width: 345px;
    }

    .search-form {
        display: flex;

        .search-input {
            appearance: none;
            border: 1px solid $input;
            border-right: 0;
            color: $input-text;
            padding: 9px 5px 9px 10px;
            transition: all 0.3s linear;
            width: 165px;
        }

        #search-button {
            appearance: none;
            background: $white;
            border: 1px solid $input;
            border-left: 0;
            cursor: pointer;
            padding-top: 5px;
            width: 35px;

            svg {
                fill: $input;
            }

            &:hover {
                background: $input;
            }

            &:hover svg {
                fill: $white;
            }
        }
    }
}

.search-form {
    &:hover #search-button {
        background: $input;
    }

    &:hover svg#search-icon {
        fill: $white;
    }
}

.language-chooser {
    ul {
        display: flex;
        line-height: 3;

        li {
            margin-right: 1em;

            &:last-of-type {
                margin-right: 0;
            }

            a {
                @include mixin.font-set(0.85em, $input, bold);

                text-transform: uppercase;
            }

            &.lang a {
                @include mixin.font-set(0.85em, $main, bold);

                text-decoration: underline;
            }

            &.lang.active a {
                @include mixin.font-set(0.85em, $input, bold);

                cursor: text;
                text-decoration: none;
            }
        }
    }
}

ul#page-navigation > li.active {
    a {
        color: $error;
    }

    .menu-wrapper {
        li {
            &.active {
                box-shadow: 0 0.05em 0 0 $error;
            }

            a {
                color: $black;
            }

            .active_link {
                color: $error;
            }
        }
    }
}

.is-homepage {
    .menu-icon {
        display: none;
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    .page-header {
        margin: 0 $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    .page-header {
        align-items: normal;
        flex-direction: column;
        margin: $width-small;
        position: relative;

        .search-form {
            .search-input {
                font-size: 1.2em;
                width: calc(100% - 35px);
            }
        }

        .logo {
            height: auto;
            margin: 0 0 1em;
            max-width: 60%;
        }
    }

    .is-homepage {
        #page-navigation {
            display: block;

            .active {
                box-shadow: -0.4em 0 0 0 $black;
            }
        }
    }

    #openMenu.hide-icon {
        display: none;
    }

    #closeMenu.show-icon {
        display: block;
    }

    .menu-icon {
        display: block;
    }

    .language-chooser {
        display: none;
    }

    .show-body nav .language-chooser {
        display: block;
    }
}
