@use 'variable' as *;
@use 'mixin';

.row-box-bg {
    background: $main;
    color: $white;
    padding: 1em $width-large;

    @media (max-width: 73em) {
        padding: 25px $width-middle;
    }

    @media (max-width: 55em) {
        padding: 25px $width-small;
    }

    .row {
        justify-content: space-between;
        margin-left: 0;

        @media (max-width: 55em) {
            margin: 0;
        }
    }

    .header-text {
        p {
            @include mixin.font-set(1.5em, $white, 300);

            max-width: 30em;
            text-align: left;
        }
    }
}

.aldryn-newsblog-latest-articles {
    p {
        color: $main;
    }

    a:hover {
        text-decoration: underline;
    }

    h2 {
        color: $main;
        font-size: 1.8em;
        font-weight: bold;

        a {
            color: $main;
            text-decoration: underline;
        }
    }
}

.news-container .aldryn-newsblog-article {
    display: flex;
    flex-direction: column;

    .category {
        display: none;
        order: 1;
    }

    h2 {
        margin-bottom: 0;
        order: 2;

        a {
            @include mixin.font-set(0.8em, $main, 600);

            text-decoration: underline;
        }
    }

    .lead {
        order: 4;
    }

    .aldryn-newsblog-meta {
        font-size: 1.1em;
        order: 3;
    }

    .author,
    .lead p {
        margin-bottom: 0.5em;
        text-align: left;

        a {
            color: $main;
        }
    }

    p {
        @include mixin.font-set(1em, $main, 300);
    }

    .date {
        margin-bottom: 0.2em;
    }
}

.btn-arrow {
    &::after {
        content: ' »';
    }
}

.aldryn-newsblog-articles:last-of-type .aldryn-newsblog-article {
    margin-bottom: 0;
}
