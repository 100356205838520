@use 'variable' as *;
@use 'mixin';

nav {
    border-bottom: 0.2em solid $nav-line;
    border-top: 0.2em solid $nav-line;
    display: flex;
    justify-content: space-between;
    padding: 0 $width-large;

    #page-navigation {
        .menu-wrapper {
            display: none;
        }

        li {
            display: inline-block;
            line-height: 3;
            margin-right: 1em;

            a {
                @include mixin.font-set(1em, $title, bold);

                &:hover {
                    color: $error;
                }
            }
        }

        .active {
            box-shadow: 0 0.2em 0 0 $error;

            .menu-wrapper {
                display: block;
            }
        }
    }

    .menu-wrapper {
        .bg {
            border-bottom: 0.05em solid $nav-line;
            left: 0;
            margin: 0 $width-large;
            position: absolute;
            width: 100 - $width-large * 2;

            @media (max-width: 73em) {
                margin: 0 $width-middle;
            }

            @media (max-width: 55em) {
                margin: 0 $width-small;
            }

            li.active,
            li.lastactive {
                box-shadow: 0 0.05em 0 0 $error;
            }

            a {
                &:hover {
                    color: $error;
                }
            }
        }
    }
}

.is-homepage {
    nav {
        border-bottom: 0.4em solid $nav-line;

        #page-navigation {
            padding-bottom: 0;
            padding-top: 0;

            .active {
                box-shadow: 0 0.36em 0 0 $error;
            }
        }
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    nav {
        padding: 0 $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    nav {
        border: 0;
        flex-direction: column;
        margin: 0;
        padding: 0 $width-small;

        #page-navigation {
            border: 0;
            display: none;

            .active {
                box-shadow: -0.1em 0 0 0 $error;

                .menu-wrapper {
                    display: none;
                }
            }

            li {
                display: block;
                line-height: 3;
                margin-right: 1em;
                padding: 0 0 0 1em;

                a {
                    @include mixin.font-set(1em, $title, bold);
                }
            }
        }
    }
}
