$bg: #f1f1f1;
$bg-table: #f4f4f4;
$black: #000;
$border2: #808080;
$border3: #e4e4e4;
$border: #dadada;
$box-shadow: #c9c9c9;
$column-text: #013893;
$column: #f5f9ff;
$debug: #ccc;
$error: #d9141e;
$hover-color: #015184;
$info: #cab999;
$input-text: #666;
$input: #a5a5a4;
$main: #003893;
$nav-line: #dadada;
$success: #32c518;
$title: #333;
$warning: #f6d128;
$white: #fff;
$less-white: #ececec;

$width-large: 14vw;
$width-middle: 4.66667vw;
$width-small: 1em;
