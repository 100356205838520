@use 'variable' as *;

footer {
    border-top: 0.05em solid $nav-line;
    margin: 2em 0 2.5em;
    padding: 0 $width-large;
    padding-top: 6px;

    .row {
        margin: 0;
    }

    .col-lg-4,
    .col-lg-3 {
        align-items: center;
        display: flex;
        padding: 2em 1.5vw 0 0;

        img {
            height: 3.7em;
            margin-right: 1em;
            max-width: 7.5em;
        }

        p {
            margin: 0;
            text-align: left;

            a {
                color: $main;
                text-decoration: underline;
            }
        }
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    footer {
        padding: 10px $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/

@media (max-width: 55em) {
    footer {
        margin: 1em 0 0;
        padding: 10px $width-small;
    }
}
